<template>
  <div class="d-flex flex-column align-items-center">
    <div
      class="outer-arc"
      :id="`outer-arc-${widget.id}-${previewMode}`"
    >
      <div class="inner-text">
        <span
          class="inner-text__value"
          id="progress-value"
        >
          {{ computedValue }}/
        </span>
        <span
          class="inner-text__total"
        >
          {{ total }}
        </span>
      </div>
    </div>

    <div class="arc-text pt-2">{{chartData.label}}</div>
  </div>
</template>

<script>
export default {
  name: "ArcPieChart",
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    widget: {
      type: Object,
      default: () => {}
    },
    previewMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      computedValue: 0,
      computedTotal: 0,
      animationInterval: null,
      intersectionObserver: null,
      chartAnimatedFlag: false,
      outerArc: null
    }
  },
  watch: {
    chartData () {
      this.animateChart()
    }
  },
  mounted () {
    this.computedTotal = this.total
    this.initiateIntersectionObserver()
  },
  computed: {
    total () {
      return parseInt(this.chartData.total)
    },
    chartValue () {
      return parseInt(this.chartData.value)
    },
    color () {
      return this.chartData.color ? this.chartData.color : "#FF5632"
    }
  },
  methods: {
    initiateIntersectionObserver () {
      const options = {
        threshold: 0.25
      }
      const callback = (entries) => {
        if (entries[0].isIntersecting === true) {
          if (!this.chartAnimatedFlag) {
            this.animateChart()
          }
          this.chartAnimatedFlag = true
        }
      }
      this.intersectionObserver = new IntersectionObserver(callback, options)
      this.outerArc = document.getElementById(`outer-arc-${this.widget.id}-${this.previewMode}`)
      this.intersectionObserver.observe(this.outerArc)
    },
    animateChart () {
      const computationType = this.computedValue < this.chartValue ? "increment" : "reduction"
      const valueDifference = Math.abs(this.computedValue - this.chartValue)
      const totalTimer = 1000
      let interval = totalTimer / valueDifference
      interval = interval > 50 ? 50 : interval

      if ((this.computedValue === this.chartValue) && (this.computedTotal !== this.total)) {
        // This is to handle a case where the user changes the target value; the chart is reset
        this.computedValue = 0
        this.animateChart()
        return
      }

      this.animationInterval = setInterval(() => {
        if (this.computedValue === this.chartValue) clearInterval(this.animationInterval)
        else {
          computationType === "increment" ? this.computedValue++ : this.computedValue--
          const deg = this.computedValue / this.total * 360
          this.outerArc.style.background = `conic-gradient(${this.color} ${deg}deg, #efefef 0deg)`
        }
      }, interval)

      this.computedTotal = this.total
    }
  }
}
</script>

<style scoped lang="scss">

.outer-arc {
  width: 180px;
  height: 180px;
  position: relative;
  border-radius: 50%;
  background: conic-gradient(#C5805D 0deg, $grey 0deg);
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    background-color: $white;
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }

  .inner-text {
    position: relative;
    display: flex;
    align-items: baseline;
    &__value {
      color: $slate;
      font-weight: bold;
      font-size: 36px;
    }
    &__total {
      color: $slate80;
      font-size: 20px;
    }
  }
}

.arc-text{
  text-align: center;
  color: $slate60;
  font-size: 14px;
}
</style>
